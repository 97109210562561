<script>
import { MyComponent } from "@/components/MyComponent.js";
import api from "@/api";
import Vue from "vue";

export default {
  name: "resetPassword",
  mixins: [MyComponent],
  data: function () {
    return {
      password: "",
      confirmPassword: "",
    };
  },

  computed: {
    validationId() {
      return this.$route.params.valId;
    },
  },
  methods: {
    resetPassword: function () {
      if (this.password.length < 8) {
        alert("Password must be 8 chars or more.");
        return;
      }
      if (this.password !== this.confirmPassword) {
        alert("Passwords do not match.");
        return;
      }
      const data = {
        password: this.password,
        validationId: this.validationId,
      };

      const url = `/validation/`;
      api.Util.axios
        .post(url, data)
        .then(() => {
          Vue.$toast.success("Reset successful. You may login.");
          this.$router.push({ name: "Login" });
        })
        .catch((err) => {
          console.error(err);
          alert("Unable to reset password.");
        });
    },
    getPostresetPassword: function () {
      var returnComponent = this.$route.params.ReturnComponent;
      if (returnComponent) {
        this.$router.push({ name: returnComponent });
      } else {
        api.Util.axios.get("/postresetPassword").then((response) => {
          if (response.data.location) {
            window.location.replace(response.data.location);
          }
        });
      }
    },
  },
};
</script>

<template>
  <div id="resetPassword">
    <div class="page-header">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-sm-6">
            <div id="box" class="p-2 mt-3 border rounded">
              <h3 class="text-center">Register</h3>
              <form
                role="form"
                class="resetPassword-form"
                @submit.prevent="resetPassword"
              >
                <div class="form-group mt-2">
                  <label for="exampleInputPassword1">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    id="exampleInputPassword1"
                    v-model="password"
                  />
                </div>

                <div class="form-group mt-2">
                  <label for="exampleInputPassword2">Confirm Password</label>
                  <input
                    type="password"
                    class="form-control"
                    id="exampleInputPassword2"
                    v-model="confirmPassword"
                  />
                </div>

                <div class="form-group mt-2">
                  <button class="btn btn-primary log-btn">Register</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#box {
  background: white;
}
</style>
